@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap");


body {
  background-image: linear-gradient(to bottom, #002568 20%, #135ADE 100%, #d9d9d9);
  color: #fff;
}


* {
  margin: 0;
  padding: 0;
  font-family: "Comfortaa", cursive;
}

body {
  overflow-y: hidden;
}

.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  color: white;
  justify-content: center;
  background-image: linear-gradient(to bottom, #002568 20%, #135ADE 100%, #d9d9d9);
}


.auth-form-container,
.login-form,
.register-form {
  display: flex;
  flex-direction: column;

}



@media screen and (min-width: 60px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
}

label {
  text-align: left;
  padding: 0.25rem 0;
  font-family: Comfortaa;
}

input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}




.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}