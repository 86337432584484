.container {
  background: #002568;
  height: 95vh;
  overflow-y: hidden;
}

.client {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userHead {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 3px solid #5453e0;
  position: relative;
}

.userHead+h4 {
  font-weight: bold;
  margin-top: 1rem;
}

.userAvatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.goBack {
  display: flex;
  align-items: center;
  background: none;
  outline: none;
  margin-top: 2rem;
}

.goBack span {
  margin-left: 1rem;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  position: relative;
}

.goBack span::after {
  content: '';
  position: absolute;
  bottom: -16px;
  left: 0;
  width: 60%;
  height: 4px;
  background: #0077ff;
  border-radius: 10px;
}

.clientsWrap {
  margin-top: 1.5rem;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  height: 80%;
  max-height: calc(100vh - 120px);
  padding: 0.6rem;
  position: relative;
  overflow-y: hidden;
  background-image: linear-gradient(to bottom, #002568 20%, #135ADE 100%, #d9d9d9);

}

.uservisualize {
  position: absolute;
  bottom: 80%;
  left: 100%;
}

.audiovisualize {
  position: absolute;
  bottom: 0px;
  right: 10px;
}

.topic {
  font-weight: bold;
  text-align: center;
}

.clientsList {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.question {
  margin-top: 25px;
}

.actionBtn {
  background-image: linear-gradient(79deg, #14023e, #02030d 25%, #01104b);
  outline: none;
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem;
  border-radius: 20px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  gap: 6px;
}

/* .actionBtn:hover {
  background: #333333;
} */

.actionBtn span {
  font-weight: bold;
  margin-left: 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions {
  display: flex;
  align-items: center;
}

.micBtn {

  width: 45px;
  height: 45px;
  box-sizing: content-box;
  border-radius: 30px;
  padding: 5px;
}

.footer {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}